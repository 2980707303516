import React from "react"

//Import de composants
import LegalLayout from "../../components/legals/LegalLayout"
import Article from "../../components/legals/ArticleMaker"
import Summary from "../../components/legals/Summary"

//Import de fichier
import rgpd from '../../utils/Legals/rgpd.json'


/**
 *
 * @returns Le corps principal de la page
 * @constructor
 */
export default function Rgpd({location}){

  return(
    <LegalLayout activeLink={location.pathname}>

      <div className="rgpd">
        <div className="rgpd__head"><h1>RGPD : <h3>LA PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL</h3></h1></div>

        <p className="opening-text">
          Lorsque vous choisissez d’externaliser tout ou partie de l’hébergement de vos données
          auprès de GetCaaS, vous nous confiez une part de votre patrimoine informationnel.
          Nous sommes conscients des enjeux que cela peut représenter pour votre organisation,
          notamment en matière de conformité réglementaire.
          C’est pourquoi nous mettons à votre disposition l’information la plus complète possible,
          à propos des enjeux en matière de protection des données à caractère personnel.
        </p>
        {
          rgpd.fr.map((art) => {
            return <Article key={art.anchor} article={art} prefixClass="" />
          })
        }
      </div>
      <Summary tabTitle={Array.from(rgpd.fr, (el) => {return [el.ancre, el._id]}) }/>
    </LegalLayout>
  )
}